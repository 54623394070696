import { PrismicPage, PrismicPageDataBodyMultiColumnContent } from '../../graphql';
import React, { Component, ReactNode } from 'react';
import PrismicImage from '../controls/PrismicImage';
import PrismicStructuredText from '../controls/PrismicStructuredText';
import { graphql } from 'gatsby';
import SectionTitle from '../controls/SectionTitle';
import PrismicIcon from '../controls/PrismicIcon';

export const MultiColumnContentSliceKey = 'multi_column_content';
export const SpecsContentSliceKey = 'specs';
export const StoreCredentialsContentSliceKey = 'store_credentials';

/*
 * That ColorMap makes the select field from prismic to be non-dynamic.
 * It should be optimized to support any values from prismic at some point.
 * */
export const ColorMap = new Map([
  ['CAA Forest Green Button', 'is-caa-forestgreen'],
  ['CAA Gray Button', 'is-caa-darkergray'],
  ['CAA Red Orange Button', 'is-caa-redorange'],
]);
export const defaultColor = ColorMap.get('CAA Forest Green Button');

export default class MultiColumnContentSlice extends Component<MultiColumnContentSliceProps> {
  render(): ReactNode {
    const { sliceData } = this.props;

    const isVertical = sliceData.primary?.rendering_mode === 'vertical';
    const isEqualHeight = sliceData.primary?.equal_height ?? false;

    const numberOfItems = sliceData?.items?.length || 0;

    let columnWidth = 'is-full';
    if (!isVertical) {
      if (numberOfItems < 3 || (numberOfItems % 3 === 0 && numberOfItems % 4 !== 0)) {
        columnWidth = 'is-third';
      } else {
        columnWidth = 'is-quarter';
      }
    }

    const normItems = sliceData?.items || [];
    const contentElements: ReactNode[] = [];

    let index = 0;
    for (const item of normItems) {
      if (!item) {
        continue;
      }

      let imageNode: ReactNode = null;

      const spacerClass = item?.mode === 'Spacer' ? 'spacer' : '';
      const colorClass = item.mode ? ColorMap.get(item.mode) : defaultColor;

      if (item?.image?.url) {
        imageNode = (
          <div className="neo-multi-column-content-image is-image">
            <PrismicImage image={item?.image} />
          </div>
        );
      }

      if (item.item_icon) {
        imageNode = (
          <div className="neo-multi-column-content-image is-icon">
            <PrismicIcon icon={item.item_icon} size={'4x'} />
          </div>
        );
      }

      let titleNode: ReactNode = null;
      if (item.item_title) {
        titleNode = <div className="neo-multi-column-content-title">{item.item_title}</div>;
      }

      let contentAlign = item.item_align || '';
      if (contentAlign) {
        contentAlign = ` content-align-${contentAlign.toLowerCase()}`;
      }

      let contentNode: ReactNode = null;
      if (item.item_content?.html) {
        contentNode = (
          <div className={`neo-multi-column-content-desc content${contentAlign}`}>
            <PrismicStructuredText text={item.item_content} colorClass={colorClass} />
          </div>
        );
      }

      contentElements.push(
        <div key={index} className={`column ${columnWidth} ${spacerClass}`}>
          {imageNode}
          <div className= {`neo-multi-column-content-main ${
                   isEqualHeight ? 'equal-height' : ''
                 }`}>
            {titleNode}
            {contentNode}
          </div>
        </div>
      );

      index += 1;
    }

    return (
      <div className={`container neo-multi-column-content ${isVertical ? 'is-neo-vertical' : 'is-neo-horizontal'}`}>
        <SectionTitle component={sliceData.primary?.section_title} />
        <div className="columns is-multiline is-centered is-variable is-6">{contentElements}</div>
      </div>
    );
  }
}

export interface MultiColumnContentSliceProps {
  sliceData: PrismicPageDataBodyMultiColumnContent;
  pageData?: PrismicPage;
}

// noinspection JSUnusedGlobalSymbols
export const SliceMultiColumnContentFragment = graphql`
  fragment SliceMultiColumnContentFragment on PrismicSlicesMultiColumnContent {
    ... on PrismicPageDataBodyMultiColumnContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
        equal_height
      }
      items {
        mode
        item_icon
        item_title
        item_align
        item_content {
          html
        }
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicErrorPageDataBodyMultiColumnContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
        equal_height
      }
      items {
        mode
        item_icon
        item_title
        item_align
        item_content {
          html
          richText
        }
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicEmailSubscriptionPageDataBodyMultiColumnContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
        equal_height
      }
      items {
        mode
        item_icon
        item_title
        item_align
        item_content {
          html
        }
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicGaragesSearchPageDataBodyMultiColumnContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
        equal_height
      }
      items {
        mode
        item_icon
        item_title
        item_align
        item_content {
          html
        }
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicMerchandiseSearchPageDataBodyMultiColumnContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
        equal_height
      }
      items {
        mode
        item_icon
        item_title
        item_align
        item_content {
          html
        }
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicSearchPageDataBodyMultiColumnContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
        equal_height
      }
      items {
        mode
        item_icon
        item_title
        item_align
        item_content {
          html
        }
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelAgentsSearchPageDataBodyMultiColumnContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
        equal_height
      }
      items {
        mode
        item_icon
        item_title
        item_align
        item_content {
          html
        }
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelDealsSearchPageDataBodyMultiColumnContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
        equal_height
      }
      items {
        mode
        item_icon
        item_title
        item_align
        item_content {
          html
        }
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelPartnerDataBodySpecs {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      items {
        mode
        item_icon
        item_title
        item_align
        item_content {
          html
        }
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelPartnersSearchPageDataBodyMultiColumnContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
        equal_height
      }
      items {
        mode
        item_icon
        item_title
        item_align
        item_content {
          html
        }
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelStoreDataBodyStoreCredentials {
      id
      slice_type
      primary {
        section_title {
          html
        }
      }
      items {
        mode
        item_icon
        item_title
        item_align
        item_content {
          html
        }
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelStoreSearchPageDataBodyMultiColumnContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
        equal_height
      }
      items {
        mode
        item_icon
        item_title
        item_align
        item_content {
          html
        }
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelTalksSearchPageDataBodyMultiColumnContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
        equal_height
      }
      items {
        mode
        item_icon
        item_title
        item_align
        item_content {
          html
        }
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelTripDataBodyMultiColumnContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
        equal_height
      }
      items {
        mode
        item_icon
        item_title
        item_align
        item_content {
          html
        }
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelTripsSearchPageDataBodyMultiColumnContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
        equal_height
      }
      items {
        mode
        item_icon
        item_title
        item_align
        item_content {
          html
        }
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelStoreDataBodyMultiColumnContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
        equal_height
      }
      items {
        mode
        item_icon
        item_title
        item_align
        item_content {
          html
        }
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelTalkDataBodyMultiColumnContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
        equal_height
      }
      items {
        mode
        item_icon
        item_title
        item_align
        item_content {
          html
        }
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelDealDataBodyMultiColumnContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
        equal_height
      }
      items {
        mode
        item_icon
        item_title
        item_align
        item_content {
          html
        }
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicMerchandiseDataBodyMultiColumnContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
        equal_height
      }
      items {
        mode
        item_icon
        item_title
        item_align
        item_content {
          html
        }
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
    ... on PrismicTravelPartnerDataBodyMultiColumnContent {
      id
      slice_type
      primary {
        section_title {
          html
        }
        rendering_mode
        equal_height
      }
      items {
        mode
        item_icon
        item_title
        item_align
        item_content {
          html
        }
        image {
          alt
          url
          gatsbyImageData(width: 960, layout: CONSTRAINED)
        }
      }
    }
  }
`;
