import { PrismicTravelPartner } from '../../../graphql';
import React, { Component, ReactNode } from 'react';
import PrismicStructuredText from '../../controls/PrismicStructuredText';
import PrismicImage from '../../controls/PrismicImage';
import PrismicFontAwesomeIcon from '../../controls/PrismicFontAwesomeIcon';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import RandomAgent from '../../fragments/travel-agent/RandomAgent';
import WithGlobalResources, { InjectedGlobalResourcesProps } from '../../higher-order/WithGlobalResources';
import PrismicLink from '../../controls/PrismicLink';
import { ResolveTravelLinkItems } from '../../../utils/DynamicLinksHelper';

export const TravelPartnerContentSliceKey = '!internal_travel_partner_content_slice';

const TravelPartnerContentSlice = WithGlobalResources(
  class TravelPartnerContentSliceImpl extends Component<TravelPartnerContentSliceProps & InjectedGlobalResourcesProps> {
    render(): ReactNode {
      const { pageData, globalResources } = this.props;

      let titleElement: ReactNode = null;

      if (pageData?.data?.page_title) {
        titleElement = (
          <div className="neo-section-title">
            <h1>{pageData?.data?.page_title}</h1>
          </div>
        );
      }

      const contentElement: ReactNode = <PrismicStructuredText text={pageData?.data?.partner_description} />;

      let showcaseElement: ReactNode = null;
      if (pageData?.data?.partner_showcase?.html) {
        showcaseElement = (
          <div className="neo-text-block is-caa-lightgray has-text-caa-gray">
            <PrismicStructuredText text={pageData?.data?.partner_showcase} />
          </div>
        );
      }

      let benefitsElement: ReactNode = null;
      if (pageData?.data?.member_benefits?.html) {
        benefitsElement = (
          <div className="neo-text-block is-caa-forestgreen">
            <PrismicStructuredText text={pageData?.data?.member_benefits} />
          </div>
        );
      }

      let phoneLink: ReactNode = null;
      if (pageData?.data?.partner_phone) {
        const phoneNumber = parsePhoneNumberFromString(pageData?.data?.partner_phone, 'CA');

        if (phoneNumber && phoneNumber.isValid()) {
          phoneLink = (
            <li>
              <a href={phoneNumber?.getURI()}>
                <PrismicFontAwesomeIcon icon={'mobile-alt'} size={'2x'} />
                {globalResources.call_partner_cta_text?.replace('{phone}', phoneNumber?.formatNational())}
              </a>
            </li>
          );
        }
      }

      return (
        <div className="container neo-travel-partner">
          {titleElement}

          <div className="columns is-variable is-8">
            <div className="column is-two-thirds content">
              {contentElement}
              {showcaseElement}
              {benefitsElement}
            </div>
            <div className="column has-border-left-caa-lightgray has-no-border-mobile">
              <div className="neo-travel-partner-logo has-text-centered">
                <PrismicImage
                  image={pageData?.data?.partner_logo ?? null}
                  className="has-max-height-180"
                  imgStyle={{ objectFit: 'contain' }}
                />
              </div>
              <ul className="neo-travel-partner-nav">
                {ResolveTravelLinkItems(globalResources.travel_partner_page_side_nav, {
                  travelPartner: pageData as PrismicTravelPartner,
                }).map((item, index) => (
                  <li key={index}>
                    <PrismicLink to={item.link_dest}>
                      <PrismicFontAwesomeIcon icon={item.link_icon} size={'2x'} />
                      {item.link_text}
                    </PrismicLink>
                  </li>
                ))}
                {phoneLink}
              </ul>
              <RandomAgent travelPartnerId={pageData?.id} />
            </div>
          </div>
        </div>
      );
    }
  }
);
export default TravelPartnerContentSlice;

export interface TravelPartnerContentSliceProps {
  sliceData: any;
  pageData?: PrismicTravelPartner;
}
