module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"caaneo-website-uat","accessToken":"MC5ZaWFvcWhBQUFPdnM0MjRC.dmvvv71hOyrvv73vv70lcG8sSu-_vTJ3KO-_ve-_ve-_vUnvv70p77-9ZO-_ve-_vTww77-9UyQ","promptForAccessToken":true,"apiEndpoint":"https://caaneo-website-uat.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://dev.website.caaneo.ca"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"CAA North & East Ontario (dev)","short_name":"CAA NEO (dev)","start_url":"/","background_color":"#ffffff","theme_color":"#1a509b","display":"minimal-ui","icon":"src/assets/images/caa-neo-logo-256.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"881b01e5e2e36bba71ee7b28f31f45f4"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
